<template>
    <div class="LabelRightBox mt-1">
        <div class="row">
            <div class="col-md-12">
                <div class="table-responsive">
                    <table class="table table-sm mb-0 table-bordered">
                        <tbody>
                        <tr>
                            <th style="width: 40%;">Category</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('categories_info') && my_asset.categories_info" class="categories_info">{{ my_asset.categories_info.map(category => category.name).join('>') }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Folder</th>
                            <td>
                                <span v-if="my_asset.hasOwnProperty('folders_info') && my_asset.folders_info" class="folders_info">{{ my_asset.folders_info.map(folder => folder.name).join('>') }}</span>
                                <span v-else>-</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Is Asset</th>
                            <td>
                                <span class="is_asset">{{ ['No', 'Yes', 'Unknown'][my_asset.is_asset] || '-' }}</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Is Hero</th>
                            <td>
                                <span class="is_hero">{{ ['No', 'Yes', 'Unknown'][my_asset.is_hero] || '-' }}</span>
                            </td>
                        </tr>
                        <tr>
                            <th style="width: 40%;">Asset Usage</th>
                            <td>
                                <span class="asset_usage">{{ ['Off Camera', 'On Camera', 'Unknown'][my_asset.asset_usage] || '-' }}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <p class="mt-2 mb-1"><i class="bx bx-detail font-size-16 text-af-accent"></i> <b>Label</b></p>

                <div class="table-responsive">
                    <table class="table table-sm mb-0 table-bordered">
                        <tbody>
                            <tr>
                                <th>Type</th>
                                <td>
                                    <b-form-radio-group v-model="addForm.type">
                                        <b-form-radio value="pdf" class="custom-radio custom-radio-af-accent"> PDF</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <th>First Image</th>
                                <td>
                                    <b-form-radio-group v-model="addForm.first_image">
                                        <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> Featured Image</b-form-radio>
                                        <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> First Image</b-form-radio>
                                        <b-form-radio :value="2" class="custom-radio custom-radio-af-accent"> Last Image</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <th>Production</th>
                                <td>
                                    <b-form-radio-group v-model="addForm.with_entity_logo">
                                        <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                        <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <th>Studio</th>
                                <td>
                                    <b-form-radio-group v-model="addForm.with_entity_group_logo">
                                        <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                        <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <th>All Images?</th>
                                <td>
                                    <b-form-radio-group v-model="addForm.all_images">
                                        <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                        <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <th>Asset Details?</th>
                                <td>
                                    <b-form-radio-group v-model="addForm.asset_details">
                                        <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                        <b-form-radio :value="0" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <th>Where to?</th>
                                <td>
                                    <b-form-radio-group v-model="addForm.where_to">
                                        <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Stay Here</b-form-radio>
                                        <b-form-radio :value="2" class="custom-radio custom-radio-af-accent"> Status Page</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>
                                    <b-form-radio-group v-model="addForm.send_email">
                                        <b-form-radio :value="1" class="custom-radio custom-radio-af-accent"> Yes</b-form-radio>
                                        <b-form-radio :value="2" class="custom-radio custom-radio-af-accent"> No</b-form-radio>
                                    </b-form-radio-group>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="text-center"><button class="btn btn-primary af-accent btn-sm btn-block" @click="performAction(my_asset, 'exportAsset', $event, addForm)">GENERATE</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LabelRightBox',
    props: {
        item_type: {},
        my_asset: {},
        performAction: {},
    },
    data() {
        return {
            addForm: {
                type: 'pdf',
                first_image: 0,
                all_images: 0,
                with_entity_logo: 0,
                with_entity_group_logo: 0,
                asset_details: 0,
                where_to: 1,
                send_email: 1,
            }
        };
    }
}
</script>
