<template>
    <div class="cm_list_view asset-list-item" :class="getAssetClasses(my_asset, selected_asset_id)"  :style="(is_actions_bar_opened && (!selected_ids.includes(my_asset.id) && should_hide_unselected)) ? 'display:none;' : ''">

        <SelectableItemActions
            :add-to-selected-list="addToSelectedList"
            :item="my_asset"
            :bulk_actions_customer_id="bulk_actions_customer_id"
            :is_actions_bar_opened="is_actions_bar_opened"
            :preform-bulk-action="preformBulkAction"
            :selected_ids="selected_ids"/>


        <div class="cm_list_row row">
            <div class="col-md-12 col-xl-2">
                <MyBCarouselImages parent_class="cm_list_view_image" :my_asset="my_asset" :show-captiom-img="showCaptiomImg" :required_image_size="'asset_list'"/>

                <div class="common_col_bg_box" v-if="my_asset.hasOwnProperty('files_info') && my_asset.files_info">
                    <ManagedStorageFiles
                        :ms_id="my_asset.id"
                        object_type="assets"
                        :item_info="my_asset"
                        :item_type="item_type"
                        :perform-action="performAction"
                    />
                </div>

                <div class="common_col_bg_box" v-if="my_asset.hasOwnProperty('asset_meta') && my_asset.asset_meta && my_asset.asset_meta.hasOwnProperty('asset_links') && my_asset.asset_meta.asset_links">
                    <div class="row">
                        <div
                            v-for="(custom_field, key) in my_asset.asset_meta.asset_links"
                            class="col-12 col-md-12" :class="customFieldsClass('asset_links')">
                            <div class="cm_usage">
                                <span>URL #{{ key + 1}}</span>
                                <p v-if="custom_field.field_value">
                                    <i class="fa fa-link"></i> <span class="cm_list_inner_common_value"><a class="text-dark" :href="custom_field.field_value" target="_blank">{{ custom_field.field_name || ('URL #' + (key + 1)) }}</a></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div class="col-xl-10">
                <div class="row cm_line_row">
                    <div class="col-md-6 col-xl-5">
                        <div class="cm_list_title_content">
                            <div class="cm_list_view_title">
                                <h3><a :href="'/' + item_type + '/assets/' + my_asset.id + '/view'"><i class="bx" :class="my_asset.ownership_type === 1 ? 'bx-cube-alt' : 'bx-time'"></i> {{ my_asset.name | titleize }}</a></h3>
                            </div>
                            <AssetIconList class="mt-2" :my_asset="my_asset" :item_type="item_type" :perform-action="performAction" :selected_view="selected_view"/>

                        </div>
                    </div>
                    <div class="col-md-6 col-xl-7">
                        <div class="cm_status_title_content">
                            <div class="cm_list_view_title"><h3><a href="#" :class="'status-btn status-btn-' + my_asset.status" v-html="getAssetStatusFrom(my_asset)"></a></h3></div>
                            <div class="cm_status_ul mt-2">
                                <ul>
                                    <li>
                                        <p>Total <span class="total_box_bg">{{ my_asset.quantity }}</span></p>
                                    </li>
                                    <li v-if="(selected_filtering_module_id || current_module.id) !== 3">
                                        <p>Available <span class="available_box_bg">{{ my_asset.available_quantity || my_asset.quantity }}</span></p>
                                    </li>
                                    <li v-if="(selected_filtering_module_id || current_module.id) !== 3">
                                        <p>Reserved <span class="reserved_box_bg">{{ my_asset.reserved_quantity || 0 }}</span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row cm_list_view_row">
                    <div class="col-md-6 col-xl-5">
                        <div class="common_col_desc asset-description" v-if="my_asset.description && my_asset.description !== ''">
                            <p>{{ my_asset.description }}</p>
                        </div>

                        <div v-if="selected_view.id !== 6" class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage folders_list">
                                        <span v-iconize="'folders'">Folder</span>
                                        <span v-if="my_asset.hasOwnProperty('folders_info') && my_asset.folders_info"
                                              class="change-folder-action" style="float: right;">
                                            <a href="javascript:void(0);" @click="performAction(my_asset, 'changeFolder', $event)">Change</a>
                                        </span>
                                        <b-breadcrumb v-if="my_asset.hasOwnProperty('folders_info') && my_asset.folders_info">
                                            <b-breadcrumb-item
                                                v-for="(folder_info, index) in my_asset.folders_info"
                                                class="breadcrumb-folder-item"
                                                :key="index"
                                                :class="index === my_asset.folders_info.length-1 ? 'active-item' : ''"
                                                :href="'/' + item_type + '/assets/?queue=folder&id=' + folder_info.id">
                                                {{ folder_info.name }}
                                            </b-breadcrumb-item>
                                        </b-breadcrumb>
                                        <p v-else><span>-</span></p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage folders_list">
                                        <span v-iconize="'departments'">Department</span>
                                        <span v-if="my_asset.hasOwnProperty('department_info') && my_asset.department_info"
                                              class="change-folder-action" style="float: right;">
                                            <a href="javascript:void(0);" @click="performAction(my_asset, 'changeDepartment', $event)">Change</a>
                                        </span>
                                        <div v-if="my_asset.hasOwnProperty('department_info') && my_asset.department_info">
                                            <span class="department_info">{{ my_asset.department_info.name }}</span>
                                        </div>
                                        <p v-else><span>-</span></p>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="cm_usage categories_list">
                                        <span v-iconize="'categories'">Category</span>
                                        <b-breadcrumb
                                            v-if="my_asset.hasOwnProperty('categories_info') && my_asset.categories_info">
                                            <b-breadcrumb-item
                                                v-for="(category_info, index) in my_asset.categories_info"
                                                :key="index"
                                                class="breadcrumb-category-item"
                                                :class="index === my_asset.categories_info.length-1 ? 'active-item' : ''"
                                                :href="'/' + item_type + '/assets/?queue=category&id=' + category_info.id">
                                                {{ category_info.name }}
                                            </b-breadcrumb-item>
                                        </b-breadcrumb>
                                        <p v-else><span>-</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div v-if="selected_view.id !== 6" class="common_col_bg_box">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>Internal Reference</span>
                                        <p><i class="bx bx-detail"></i> <span>{{ my_asset.internal_reference || '-' }}</span></p>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="cm_usage">
                                        <span>External Reference</span>
                                        <p><i class="bx bx-detail"></i> <span>{{ my_asset.external_reference || '-' }}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <b-skeleton-wrapper class="af-skeleton-block" :loading="isLoadingAssetDetails">
                            <template #loading>
                                <div class="common_col_bg_box" v-for="item in [0,1,2]">
                                    <b-skeleton-table
                                        class="mb-0"
                                        :hideHeader="true"
                                        :showFooter="true"
                                        :key="'table_'+item"
                                        :rows="1"
                                        :columns="3"
                                        :table-props="tableProps"
                                    ></b-skeleton-table>
                                </div>
                            </template>

                            <ErrorBoundary>

                                <StorageOrderLeftBox v-if="selected_view.id === 2"
                                                     :my_asset="my_asset"
                                                     :item_type="item_type"/>
                                <AcquisitionLeftBox v-else-if="selected_view.id === 3"
                                                    :my_asset="my_asset"
                                                    :item_class="'border_r'"
                                                    :item_type="item_type"/>
                                <DispositionLeftBox v-else-if="selected_view.id === 4"
                                                    :my_asset="my_asset"
                                                    :perform-action="performAction"
                                                    :item_type="item_type"/>
                                <LabelLeftBox
                                    v-else-if="selected_view.id === 6"
                                    :my_asset="my_asset"
                                    :perform-action="performAction"
                                    :item_type="item_type"/>
                                <div v-else></div>
                            </ErrorBoundary>
                        </b-skeleton-wrapper>

                        <div class=" cm_list_view_tag">
                            <div class="tag-wrap list-show">
                                <p v-if="selected_view.id === 6" class="mb-1"><b>Tags</b></p>

                                <ul v-if="my_asset.tags_info">
                                    <li v-for="tag in my_asset.tags_info">
                                        <a :href="'/' + item_type + '/assets?queue=tag&id=' + tag.id + ''">{{ tag.name }}</a>
                                    </li>
                                </ul>
                                <tags-manager
                                    v-if="selected_view.id !== 6"
                                    class="mt-2"
                                    ref="tags-manager1"
                                    :product="my_asset"
                                    :all_tags_list="all_tags_list"
                                    :asset_key="assetKey"
                                    @on-add-tag="myAddTag"
                                    @on-remove-tag="myRemoveTag"
                                ></tags-manager>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xl-7 cm_order">
                        <b-skeleton-wrapper class="af-skeleton-block" :loading="isLoadingAssetDetails">
                            <template #loading>
                                <div class="common_col_bg_box" v-for="item in [0,1,2]">
                                    <b-skeleton-table
                                        class="mb-0"
                                        :hideHeader="true"
                                        :showFooter="true"
                                        :key="'table_'+item"
                                        :rows="1"
                                        :columns="3"
                                        :table-props="tableProps"
                                    ></b-skeleton-table>
                                </div>
                            </template>
                            <RightErrorBoundary>
                                <AssetLayoutRightBox v-if="selected_view.id === 0"
                                                    :my_asset="my_asset"
                                                    :perform-action="performAction"
                                                    :item_type="item_type"/>

                                <AssetUsageRightBox v-else-if="selected_view.id === 1"
                                                     :my_asset.sync="my_asset"
                                                     :item_type="item_type"
                                                    :changedRadioValueOf="changedRadioValueOf"
                                                    :getClassFor="getClassFor"/>

                                <StorageOrderRightBox v-else-if="selected_view.id === 2"
                                                      :my_asset="my_asset"
                                                      :item_type="item_type"/>
                                <AcquisitionRightBox v-else-if="selected_view.id === 3"
                                                     :my_asset="my_asset"
                                                     :item_class="'border_r'"
                                                     :received_acquisition_methods="acquisition_methods"
                                                     :perform-action="performAction"
                                                     :item_type="item_type"/>
                                <DispositionRightBoxNew v-else-if="selected_view.id === 4"
                                                     :my_asset="my_asset"
                                                     :item_type="item_type"
                                                     :perform-action="performAction"
                                                     :changed-radio-value-of="changedRadioValueOf"
                                />
                                <SustainabilityRightBox v-else-if="selected_view.id === 5"
                                                        :my_asset="my_asset"
                                                        :item_type="item_type"/>

                                <LabelRightBox
                                    v-else-if="selected_view.id === 6"
                                    :my_asset="my_asset"
                                    :perform-action="performAction"
                                    :item_type="item_type"/>

                                <ReconciliationRightBox
                                    v-else-if="selected_view.id === 7"
                                    :item_type="item_type"
                                    :my_asset="my_asset"
                                    :perform-action="performAction"
                                    :changedRadioValueOf="changedRadioValueOf"
                                    :getClassFor="getClassFor"/>

                            </RightErrorBoundary>

                        </b-skeleton-wrapper>

                        <div class="cm_list_last_update mt-1" v-if="my_asset.hasOwnProperty('latest_post_update')">
                            <p v-html="getPostText()"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="cm_list_view_more_button">

            <div v-if="hasPermission('edit_assets') || hasPermission('delete_assets')" class="btn-group my-dots-horizontal bl-auto af-accent-hover">
                <button type="button" class="btn btn-none dropdown-toggle" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                    <i class="mdi mdi-dots-horizontal"></i>
                </button>
                <AssetsActionsList :item="my_asset" :item_type="item_type" :perform-action="performAction"/>
            </div>

        </div>
        <div class="cm_list_view_btn">
            <a :href="'/' + item_type + '/assets/' + my_asset.id + '/view'"><i class="bx bx-chevron-right"></i></a>
        </div>
    </div>
</template>
<script>
import TagsManager from "../TagsManager";
import AssetsActionsList from "../AssetsActionsList";
import AssetIconList from "./AssetIconList";
import StorageOrderRightBox from "./StorageOrderRightBox";
import StorageOrderLeftBox from "./StorageOrderLeftBox";
import DispositionLeftBox from "./DispositionLeftBox";
import DispositionRightBoxNew from "./DispositionRightBoxNew";
import AssetLayoutRightBox from "./AssetLayoutRightBox";
import AcquisitionLeftBox from "./AcquisitionLeftBox";
import AcquisitionRightBox from "./AcquisitionRightBox";
import MyBCarouselImages from "./MyBCarouselImages";
import ErrorBoundary from "../../../../../components/ErrorBoundary";
import RightErrorBoundary from "../../../../../components/RightErrorBoundary";
import ManagedStorageFiles from "../../../customers/managed_storages/ManagedStorageFiles";
import SustainabilityRightBox from "./SustainabilityRightBox";
import AssetUsageRightBox from "./AssetUsageRightBox";
import SelectableItemActions from "../../../customers/managed_storages/SelectableItemActions";
import LabelLeftBox from "./LabelLeftBox";
import LabelRightBox from "./LabelRightBox";
import ReconciliationRightBox from "./ReconciliationRightBox";

export default {
    name: 'AssetListViewNew',
    components: {
        ReconciliationRightBox,
        LabelRightBox,
        LabelLeftBox,
        SelectableItemActions,
        AssetUsageRightBox,
        SustainabilityRightBox,
        ManagedStorageFiles,
        RightErrorBoundary,
        ErrorBoundary,
        MyBCarouselImages,
        AcquisitionLeftBox,
        AcquisitionRightBox,
        AssetLayoutRightBox,
        DispositionRightBoxNew,
        DispositionLeftBox,
        StorageOrderLeftBox,
        StorageOrderRightBox, AssetIconList, AssetsActionsList, TagsManager},
    data(){
        return {
            custom_class: '',
            should_hide_asset: false,
            tableProps: { bordered: false, striped: false, borderless: true, small: true }
        }
    },
    props:[
        'item_type',
        'my_asset',
        'selected_asset_id',
        'assetKey',
        'selected_view',
        'showCaptiomImg',
        'all_tags_list',
        'acquisition_methods',
        'myAddTag',
        'myRemoveTag',
        'performAction',
        'isLoadingAssetDetails',
        'set_item_value',
        'getPostTextOf',
        'getClassFor',

        'is_actions_bar_opened',
        'is_selecting_items',
        'selected_ids',
        'addToSelectedList',
        'should_hide_unselected',
        'preformBulkAction',
        'bulk_actions_customer_id',
    ],
    computed:{
        asset_is_locked(){
            return this.checkIfAssetIsLocked(this.my_asset);
        }
    },
    methods:{
        changedRadioValueOf(field_key, field_value) {
            if (['status', 'asset_usage', 'is_hero', 'is_asset', 'reconciled', 'external_reference', 'final_disposition_planning', 'fdp_status'].includes(field_key)){
                let titleized_field = field_key;
                if (titleized_field === 'reconciled'){
                    titleized_field = 'Reconciliation';
                }
                let message = "Saved "+Vue.options.filters.titleize(titleized_field)+" of {"+this.my_asset.name+"} Successfully";
                this.set_item_value('assets', this.my_asset.id, field_key, field_value, message, false);
                this.$set(this.my_asset, field_key, field_value);
            }
        },

        getPostText(){
            return this.getPostTextOf(this.my_asset);
        }
    }
}
</script>
